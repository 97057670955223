<template>
  <div style="padding:0 20px">
    <div class="content1">
      <div class="top">
        <div class="title">关于我们</div>
        <div class="line"></div>
        <div class="title1">ABOUT US</div>
      </div>
      <div class="bom">
        <div class="left animated"
          :class="{ bounceInLeft: show1 }">
          <div class="title">
            <div>公司简介</div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="text_f">
              壹亩地供应链管理有限公司创办于2020年4月2日，由湖南本
              在控股有限公司与湖南省创益农服务中心发起，联合创业团队共同
              出资设立，注册与实收资本5000万元，系经国家工商总局批准未冠
              地域名的智慧农业高新技术企业集团。公司实行事业部制的集团管
              控模式，集团总部负责品牌与平台建设，直属子公司湖南格灵科技
              有限公司、湖南隆服粮油有限公司、湖南农担财务有限公司分别负
              责农资农技、订单农业、涉农类金融服务等业务。
            </div>
            <div class="text_f">
              集团公司倾力打造了地道益农信息服务平台，作为助力中国乡
              村振兴的专业化、智能化、本地化信息服务平台，地道益农坚持以
              农户为主角，致力于帮助人们解决涉农生产、生活、生意中所遇到
              的各种需求，担负为农民服务的崇高使命。
            </div>
            <div class="text_f">
              地道集市属于地道益农中的新零售交易平台，平台融合B2B2C 与O2O
              电商模式，聚焦乡村农圈、城市小区、社交私域三大消费群
              体，通过数字化赋能实体门店供应链业态转型，让商家与用户之间
              互动方式更灵活，商品交付更快捷，交易成本更地道。
            </div>
            <div class="text_f">
              地道集市，信链家人。
            </div>
          </div>
        </div>
        <div class="right animated"
          :class="{ bounceInRight: show1 }">
          <img src="../assets/img/components/test.jpg"
            alt=""
            width="50%">
          <!-- <Carousel autoplay
            v-model="value"
            loop
            arrow="never"
            radius-dot>
            <CarouselItem>
              <img src="../assets/img/components/test.jpg"
                class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/swiper2.png"
                class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/swiper3.png"
                class="swiper" />
            </CarouselItem>
          </Carousel> -->
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">商城特色</div>
        <div class="line"></div>
        <div class="title1">CORPORATE MISSION AND VISION</div>
      </div>
      <div class="bom">
        <div class="item">
          <div class="img animated"
            :class="{ bounceInLeft: show2 }">
            <img src="../assets/img/components/t-4.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
          <div class="text animated"
            :class="{ bounceInRight: show2 }">
            <div class="title">引流赋能线下实体门店</div>
            商家入驻B2B2C平台，通过DIY店铺、直播、
            秒杀、拼团、助力、预售、分销、积分、
            优惠券等功能让交易更具互动化。农
            圈门店与小区驿站通过O2O功能融合益农
            微商，可快速获得高效的导购宣传与全网
            营销，形成引流、交易、复购留客机制。
            用户通过平台可快速得到最新最详的产品
            与服务信息，随时随地与商家沟通，享受
            服务。分销会员自购省钱、推广赚钱，消
            费便利性、灵活性、自由性更高。
          </div>
        </div>
        <div class="item" style="background-color:white;">
          <div class="text animated"
            :class="{ bounceInLeft: show3  }">
            <div class="title">跨业联盟与本地化服务</div>
            整合商品与服务资源，打造供货商家、农
            圈门店、小区驿站、益农微商共享、共 创、共赢、共生的新零售交易平台。通过
            在一组数字同步的应用程序中涵盖涉农生
            产、生活、生意所需的各种交易场景，聚
            焦本地化服务，实现供应链跨业联盟。融 合O2O模式的农圈门店与小区驿站，以
            高品质产品以及服务为基础，丰富购物
            场景，实现消费升级，服务升级，充分
            满足周边用户或团购的品鉴需求。
          </div>
          <div class="img animated"
            :class="{ bounceInRight: show3 }">
            <img src="../assets/img/components/t-5.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
        </div>
        <div class="item">
          <div class="img animated"
            :class="{ bounceInLeft: show4 }">
            <img src="../assets/img/components/t-6.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
          <div class="text animated"
            :class="{ bounceInRight: show4 }">
            <div class="title">聚焦涉农新零售供应链</div>
            聚焦乡村农圈、城市小区、社交私域三大
            消费群体 ，融合B2B2C 与O2O电商模
            式，赋能实体门店供应链业态转型，优化
            涉农群体消费习惯，满足涉农生产、生 活、生意所需，重构订购交易的信用体
            系。围绕工业品下乡、农产品进城供应
            链，为商家提供数字化工具与流量运营服
            务，以同域引流模式为主导，融合线上线
            下全网营销，实现多业态入驻，帮助商家
            通过实现营销裂变获得更多收益。
          </div>
        </div>
        <div class="item" style="background-color:white;">
          <div class="text animated"
            :class="{ bounceInLeft: show5  }">
            <div class="title">消费积分转换养老储备</div>
            依据《国务院办公厅关于推动个人养老金
            发展的意见》（国办发﹝2022﹞7号）文
            件精神，平台致力于打造百姓无忧未来的
            消费积分补充养老储备服务平台。用户消
            费即可获赠积分，积分通过转换可作为个
            人补充养老金进行储备。在不增加额外支
            出的基础上，将购物消费积分转化为养老
            储备，让消费不仅只是花钱，更能积攒养
            老金。同时，分销会员还可通过分享获得
            奖励，把人脉资源转化为财富。
          </div>
          <div class="img animated"
            :class="{ bounceInRight: show5 }">
            <img src="../assets/img/components/t-1.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
        </div>
        <div class="item">
          <div class="img animated"
            :class="{ bounceInLeft: show6 }">
            <img src="../assets/img/components/t-2.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
          <div class="text animated"
            :class="{ bounceInRight: show6 }">
            <div class="title">商户资金自动分账结算</div>
            同时兼容银联支付、支付宝支付、微信支
            付、充值预存余额支付等方式，预售交易
            支持全款或分期支付结算。平台开通微信
            电商收付通接口，商家可以通过平台申请
            收付通商家交易账户，用户通过微信所支
            付的购物款项实时计入商家交易账户，在
            用户确认收货后自动结算分账，商家可自
            行操作将交易款转入绑定的银行账户，有
            效地实现了交易支付的方便、快捷，充分
            保障了商家的资金安全与流动性。
          </div>
        </div>
        <div class="item" style="background-color:white;">
          <div class="text animated"
            :class="{ bounceInLeft: show7  }">
            <div class="title">强大的第三方直连对接</div>
            可批量集采商品，店铺一键搬家。与物流
            机构直连信息同步共享，自行打印电子面
            单。对接UU和达达同城配送，形成营销
            服务闭环，用户可选择门店自提与物流配
            送。商家可根据订单及实际情况，合理选
            择处理订单发货方式，商家对拼单或套餐
            订单可以实现拆单发货，方便商家多点仓
            储与分批发货，在及时保障用户体验的同
            时，合理平衡商家同城物流配送需求，手
            机移动端可实时查看订单配送状态。
          </div>
          <div class="img animated"
            :class="{ bounceInRight: show7 }">
            <img src="../assets/img/components/t-3.svg"
              width="300px"
              height="300px"
              alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content3">
      <div class="top">
        <div class="title">企业文化</div>
        <div class="line"></div>
        <div class="title1">THE ENTERPRISE CULTURE</div>
      </div>
      <div class="bom">
        <div class="item"
          v-for="(item, index) in cultureList"
          :key="index"
          @mouseleave="mouseLeave"
          @mouseover="mouseOver(item.index)">
          <div class="outside animated"
            :class="{ fadeOutDown: index == curIndex }">
            <img :src="item.img"
              class="img" />
            <div class="imgb"></div>
            <div class="text">
              <div>
                <div class="text1">{{ item.text1 }}</div>
                <div class="text2">{{ item.text2 }}</div>
              </div>
            </div>
          </div>
          <div class="inside animated"
            :class="{ fadeInUp: index == curIndex }">
            <div class="text3">
              {{ item.text3 }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="content4">
      <div class="top">
        <div class="title color">共同价值观</div>
        <div class="line"></div>
        <div class="title1 color">SHARED VALUES</div>
      </div>
      <div class="bom">
        <div class="item"
          :class="{ active: index == curIndex1 }"
          v-for="(item, index) in senseList"
          :key="index"
          @mouseleave="mouseLeave1"
          @mouseover="mouseOver1(item.index)">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- <div class="content5">
      <div class="top">
        <div class="title">发展历程</div>
        <div class="line"></div>
        <div class="title1">THE DEVELOPMENT COURSE</div>
      </div>
      <div class="bom">
        <div class="left">
          <div class="item">2019年，聚焦城南、立足城南的“天府新楼市”诞生</div>
          <div class="item li1">2014年，公司旗下PC网站“大天府网”诞生</div>
          <div class="item li2">
            2013年夏初，华阳生活百事通“华阳哪些事儿”诞生
          </div>
        </div>
        <div class="cen">
          <div class="item ci1">
            2019
            <img src="../assets/img/index/img-history1.png"
              class="img-h1" />
          </div>
          <div class="item ci2">
            2016
            <img src="../assets/img/index/img-history2.png"
              class="img-h2" />
          </div>
          <div class="item ci3">
            2014
            <img src="../assets/img/index/img-history1.png"
              class="img-h1" />
          </div>
          <div class="item ci4">
            2013
            <img src="../assets/img/index/img-history1.png"
              class="img-h1" />
          </div>
          <div class="line"></div>
        </div>
        <div class="right">
          <div class="item ri1">
            2016年，主打严选成都百分之二的好店，打造成为产品优、质量优、服务优的高端电商品牌“阳鲜生优选”诞生
          </div>
          <div class="item ri2">
            2016年，龙泉驿区综合资讯服 务平台“龙泉那些事儿”诞生
          </div>
          <div class="item ri3">
            2016年，立足天府新区， 专注家庭教育的亲子教育 平台“天府爸妈帮”诞生
          </div>
          <div class="item ri4">
            2016年，搜罗天府新区特色美食，聚集
            天府新区最火、最全面、最资格的美食 发布平台的“天府新区美食圈”诞生
          </div>
          <div class="item ri5">
            2016年，以诙谐、幽默的漫画形
            式呈现当下年轻人意识形态的“关心粮食执委会”诞生
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="content6">
      <div class="top">
        <div class="title">公司荣誉</div>
        <div class="line"></div>
        <div class="title1">COMPANY HONOR</div>
      </div>
      <div class="bom">
        <Carousel v-model="curIndex3"
          loop
          arrow="always"
          dots="none">
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div class="item"
                  v-for="(item, index) in honorList1"
                  :key="index">
                  <img class="img"
                    :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div class="item"
                  v-for="(item, index) in honorList2"
                  :key="index">
                  <img class="img"
                    :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      value: 0,
      cultureList: [
        {
          img: require("../assets/img/index/img11.png"),
          index: 0,
          text1: "诚实守信",
          text2: "Honest and trustworthy",
          text3:
            "诚实守信：诚实做人，不隐瞒自己的真实思想。不为不可告人的目的欺瞒客户、同事。讲信用，信守承诺，忠实于自己承担的义务。",
        },
        {
          img: require("../assets/img/index/img12.png"),
          index: 1,
          text1: "戒骄戒躁",
          text2: "Avoid arrogance",
          text3:
            "获得成就，不要骄傲自满。遇到挫折，不要慌乱急躁、一蹶不振，要一如既往的保持谦逊进取的精神。",
        },
        {
          img: require("../assets/img/index/img13.png"),
          index: 2,
          text1: "团结创新",
          text2: "Unity innovation",
          text3: "互相支持，互相配合，互相理解，互相包容。",
        },
        {
          img: require("../assets/img/index/img14.png"),
          index: 3,
          text1: "拥抱变化",
          text2: "Embrace change",
          text3:
            "面对变化，理性对待，充分沟通，诚意配合。对变化产生的困难和挫折，能自我调整，并正面影响和带动同事。有前瞻意识，创造变化，建立新方法、新思路。",
        },
      ],
      curIndex: null,
      senseList: [
        { text: "为客户创造价值，成客户之事，就公司之本", index: 0 },
        { text: "清楚自己想要做什么，适合做什么，将要做什么", index: 1 },
        {
          text: "乘众人之智，用众人之力，共同创造价值，共享发展成果",
          index: 2,
        },
        { text: "开放思维，不断超越，拥抱变化", index: 3 },
        {
          text: "忠诚正直，言出必行，行必有果",
          index: 4,
        },
        { text: "尊重我的客户和竞争对手，感谢他们让我更强大", index: 5 },
      ],
      curIndex1: null,
      honorList1: [
        {
          img: require("../assets/img/index/img-honor1.png"),
          text: "成都天驱科技有限公司荣获“华阳街道2017年度经济工作先进企业”荣誉",
          index: 0,
        },
        {
          img: require("../assets/img/index/img-honor2.png"),
          text: "华阳那些事儿荣获2017成都市属地互联网品牌评选活动 “区域服务类”上榜品牌",
          index: 1,
        },
        {
          img: require("../assets/img/index/img-honor3.png"),
          text: "天府发布荣获2018新浪四川微政道影响力政务微博“最佳创新传播奖”",
          index: 2,
        },
      ],
      honorList2: [
        {
          img: require("../assets/img/index/img-honor4.png"),
          text: "天府发布被评为“微政四川2018年度十佳区县政务新媒体（成都）”",
          index: 3,
        },
        {
          img: require("../assets/img/index/img-honor5.png"),
          text: "华阳那些事儿荣获2019年度成都市自媒体协会 “星力榜”",
          index: 4,
        },
        {
          img: require("../assets/img/index/img-honor6.png"),
          text: "2019年1月成都天驱科技有限公司荣获#成都地铁24小时#短视频大赛二等奖",
          index: 5,
        },
      ],
      curIndex2: null,
      curIndex3: 0,
      num: 0,
      moveL: false,
      moveR: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    leftLeave() {
      this.moveL = false;
    },
    leftOver() {
      this.moveL = true;
      let time = setInterval(() => {
        if (this.moveL) {
          if (this.num > -740) {
            this.num -= 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    rightLeave() {
      this.moveR = false;
      this.time = null;
    },
    rightOver() {
      this.moveR = true;
      let time = setInterval(() => {
        if (this.moveR) {
          if (this.num < 0) {
            this.num += 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop, 123);
      if (scrollTop >= 200) {
        this.show1 = true;
      }
      if (scrollTop >= 900) {
        this.show2 = true;
      }
      if (scrollTop >= 1200) {
        this.show3 = true;
      }
      if (scrollTop >= 1500) {
        this.show4 = true;
      }
      if (scrollTop >= 1800) {
        this.show5 = true;
      }
      if (scrollTop >= 2100) {
        this.show6 = true;
      }
      if (scrollTop >= 2400) {
        this.show7 = true;
      }
    },
  },
  created() { },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content1 {
  width: 100%;
  margin: 0 auto;
  padding: 70px 0;
  .bom {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 50%;
      .title {
        color: #4d4d4d;
        font-size: 20px;
        .line {
          width: 40px;
          height: 3px;
          background: #ef8200;
          opacity: 1;
          margin-top: 10px;
        }
      }
      .text {
        margin-top: 35px;
        color: #000014;
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: justify;
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: space-around;
      /deep/.ivu-carousel-list {
        width: 800px;
        height: 620px;
        border-radius: 10px !important;
      }
      .swiper {
        width: 800px;
        height: 620px;
        border-radius: 10px !important;
      }
    }
  }
}
.content2 {
  width: 100%;
  // height: 770px;
  background: #f0f5fa;
  padding-top: 30px;
  .bom {
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    .item {
      width: 100%;
      margin-bottom: 10px;
      padding: 0 100px;
      display: flex;
      justify-content: space-around;

      .img {
        width: 30%;
        color: #fff;
        text-align: center;
      }
      .text {
        width: 60%;
        text-indent: 2rem;
        font-size: 1.25rem;
        margin:  auto 0;
        .title {
          font-size: 1.75rem;
          margin-bottom: 30px;
        }
      }
    }
  }
}
.content3 {
  padding-top: 70px;
  width: 100%;
  height: 760px;
  .bom {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    .item {
      overflow: hidden;
      width: 350px;
      height: 500px;
      border-radius: 10px;
      .outside {
        z-index: 10;
        position: relative;
        border-radius: 10px;
        .img {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 10;
        }
        .imgb {
          width: 350px;
          height: 500px;
          background: rgba(0, 64, 159, 0.5);
          position: absolute;
          z-index: 11;
        }
        .text {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 20;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          .text1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }
          .text2 {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .inside {
        position: relative;
        z-index: 9;
        width: 350px;
        height: 500px;
        background: #ef8200;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        .text3 {
          text-align: justify;
          height: 220px;
          width: 262px;
          line-height: 24px;
          color: #ffffff;
          font-size: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.content4 {
  width: 100%;
  background-image: url("../assets/img/index/content-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 30px;
  .color {
    color: #fff;
  }
  .bom {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;
    .item {
      width: 512px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 33px;
      padding-left: 40px;
      font-size: 16px;
      color: #000028;
    }
    .active {
      position: relative;
      animation: mymove 0.5s;
      animation-fill-mode: forwards;
    }
    @keyframes mymove {
      from {
        top: 0px;
      }
      to {
        top: -10px;
      }
    }
    .item:hover {
      box-shadow: 0px 0px 6px rgba(16, 180, 252, 0.79);
    }
  }
}
.content5 {
  width: 100%;
  height: 903px;
  padding-top: 70px;
  .bom {
    width: 1036px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    .left {
      width: 394px;
      height: 690px;
      .item {
        width: 394px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 16px;
        padding-right: 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
      }
      .item:hover {
        background: #ef8200;
        color: #ffffff;
      }
      .li1 {
        margin-top: 180px;
      }
      .li2 {
        margin-top: 122px;
      }
    }
    .cen {
      width: 246px;
      height: 690px;
      position: relative;
      .item {
        width: 102px;
        height: 38px;
        background: #ef8200;
        opacity: 1;
        border-radius: 19px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 38px;
        margin: 0 auto;
        position: relative;
        .img-h1 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: -60px;
        }
        .img-h2 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          right: -60px;
        }
      }
      .ci1 {
        margin-top: 10px;
      }
      .ci2 {
        margin-top: 106px;
      }
      .ci3 {
        margin-top: 70px;
      }
      .ci4 {
        margin-top: 150px;
      }
      .line {
        position: absolute;
        top: 10px;
        left: 123px;
        z-index: -1;
        width: 6px;
        height: 650px;
        background: #ebebeb;
        border-radius: 3px;
      }
    }
    .right {
      width: 388px;
      height: 690px;
      .item {
        width: 388px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 16px;
        padding-right: 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
        line-height: 20px;
      }
      .item:hover {
        background: #ef8200;
        color: #ffffff;
      }
      .ri1 {
        margin-top: 120px;
      }
      .ri2 {
        margin-top: 15px;
      }
      .ri3 {
        margin-top: 16px;
      }
      .ri4 {
        margin-top: 16px;
      }
      .ri5 {
        margin-top: 15px;
      }
    }
  }
}
.content6 {
  width: 100%;
  height: 655px;
  background: #f0f5fa;
  padding-top: 30px;
  .bom {
    margin: 0 auto;
    width: 100%;
    height: 402px;
    margin-top: 60px;
    .carousel-item {
      width: 1600px;
      height: 402px;
      .box {
        display: flex;
        justify-content: space-around;
        width: 1104px;
        height: 402px;
        margin: 0 auto;
        .item {
          width: 340px;
          border-radius: 10px;
          background: #fff;
          img {
            width: 340px;
            height: 268px;
          }
          .text {
            width: 340px;
            height: 132px;
            color: #000014;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding-left: 26px;
            padding-right: 34px;
          }
        }
      }
    }
  }
}
.text_f {
  text-indent: 2rem;
}
</style>
